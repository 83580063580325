@import 'common';

html, body, #root {
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.spinnerWrap {
  width: 100%;
  height: 100%;

  .spinner-border {
    position: relative;
    width: 3rem;
    height: 3rem;
  }

}

.plusEdit {
  cursor: pointer;
}

button.superRadius {
  @include superRadius
}

.noSelect {
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
}

form {
  label {
    font-size: .9rem;
  }

  ::placeholder {
    font-size: .9rem;
  }

  input {
    font-size: .8rem;
  }

  button.superRadius.btn-primary {
    background-color: $colorLime;
    border-color: $colorLime;

    &:hover {
      background-color: $colorLimeHover;
      border-color: $colorLimeHover;
    }
  }

  .custom-control-label::before,
  .custom-control-label::after {
    width: 1.65rem;
    height: 1.65rem;
  }

}

.min-vh-100-less-footer {
  min-height: calc(100vh - #{$footerSpacerPx});

  .emptySpacer {
    height: calc(100vh - #{$footerSpacerPx});
  }
}

.spinnerUp1 {
  margin-top: -3em;
  height: 100%;
}

ul {
  li {
    list-style: none;
  }
}

ul.bullet-list {
  margin-left: 0;
  padding-left: 0;
  display: table;
  li {
    display: table-row
  }
  li::before {
    content: '\2B24';
    font-size: .6rem;
    display: table-cell;
    text-align: right;
    padding-right: .5rem;
  }
}

.card-divider {
  border-bottom: 1px solid $colorDividerGray;
}

.card-divider-dashed {
  border-bottom: 1px dashed $colorDividerGray;
}

.description-text {
  color: $colorBrightGray;
  font-weight: 500;
}

.required-dot-icon {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: $colorRed;
  display: inline-block;
}
