@import '../../common.scss';

.radioButtonComponent {
  label {
    position: relative;
    cursor: pointer;
  }

  .radio-text {
    position: relative;
    left: ($radioButtonRadius * 1.2)+rem;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  span::before,
  span::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  span.radio {
    &:before {
      width: $radioButtonRadius+rem;
      height: $radioButtonRadius+rem;
      background-color: $colorWhiteSmoke;
      //border: solid #DDDDDD inside 0.1px;
      border-radius: 50%;
    }
    &:after {
      left: ($radioButtonRadius/4)+rem;
      width: ($radioButtonRadius/2)+rem;
      height: ($radioButtonRadius/2)+rem;
      border-radius: 50%;
      background-color: $colorWhiteSmoke;
    }
  }

  input[type="radio"]:checked + label span.radio::before {
    background-color: $colorPurple;
  }
}
