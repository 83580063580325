@import '../../common';

#modalComponent {
  overflow: scroll;

  background-color: $colorWhiteSmoke;

  .modal-content {
    width: 100%;
    height: 100%;
  }

  .close {
    right: 30px;
    top: 20px;
  }

  button.close {
    z-index: 1070; // https://getbootstrap.com/docs/4.0/layout/overview/#z-index
  }

  .modal-dialog.modal-w770 {
    max-width: 770px;
  }

}
