@import '../../../common';

#myStandardsBallotsComponent {
  .cardRowTitle {
    font-size: 1rem;
    color: $colorBlack;
  }

  .cardRowSubtitle {
    font-size: .9rem;
    color: $colorBrightGray;
  }
}
