@import '../../../common';

#allStandardsBallotsComponent {
  .cardRowTitle {
    font-size: 1.3rem;
  }

  .cardRowSubtitle {
    font-size: 1.25rem;
  }

  #voting-card{
    .cardRowTitle {
      color: $colorBlack;
    }

    .cardRowValue{
      color: $colorBrightGray;
    }
  }
}
