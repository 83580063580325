@import '../../common';
#customerDownloadComponent {
    .cardRowTitle {
        font-size: 1.3rem;
      }
    
      .cardRowSubtitle {
        font-size: 1.25rem;
      }
    .customerDownloadLabel{
        font-weight: 600;
    }
}