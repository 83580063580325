@import '../../../common.scss';

#standardsBallotsFormCardComponent {
  .cardRowTitle {
    color: $colorBlack;
    font-size: 1.7rem;
    font-weight: 800;
  }

  .cardRowSubtitle {
    color: $colorLime;
    font-size: 1.3rem;
    font-weight: bold;
  }
}
