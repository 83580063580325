@import '../../common';

#headerComponent {
  .container {
    padding-left: 0;
    padding-right: 0;
  }

  #topBarWrapper {
    background-color: #55a51c;
  }

  #headerNav {
    background-color: $colorWhite;

    a {
      user-select: none;
      height: 84px;
      border-bottom: 5px solid $colorWhite;
      padding: 20px 15px 0;
      line-height: 35px;
      font-size: 1rem;
      color: $colorGrayTwenty;
      font-weight: 700;
      display: block;
      text-decoration: none;

      &.active {
        color: $colorLime;
        border-color: $colorLime;
      }
    }
  }


}
