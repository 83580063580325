@import '../../common';

#footerComponent {
  background-color: $colorBrightGray;
  color: $colorFooterGrayFont;

  font-size: 0.8125rem;

  h6 {
    font-family: $fontGotham;
    font-weight: 800;
    font-size: 1rem;
  }

  a {
    text-decoration: none;
    color: $colorFooterGrayFont;
  }

  ul {
    padding-left: 0;

    li {
      padding-bottom: 0.5em;
    }
  }
}
