@import '../../common';

.cardComponent {
  .card-title {
    font-family: $fontGotham;
    font-weight: 800;
    font-size: 2rem;
  }

  button {
    background-color: $colorWhite;
    color: $colorNavy;
    border: 2px solid;
    font-size: .9rem;
    font-weight: bold;
    padding-left: 34px;
    padding-right: 34px;
  }
}
