@import '../../common';

.cardFieldRowComponent {
  .cardRowTitle {
    font-size: 0.825rem;
    color: $colorLimeHover;
    font-weight: 600;
  }

  .cardRowSubtitle {
    font-size: 0.775rem;
    color: $colorBlack;
    font-weight: 600;
  }

  .cardRowValue {
    font-weight: 600;
  }

  .description.text-muted {
    font-size: 0.675rem;
  }

  .hover-field {
    &:hover {
      background-color: $colorWhiteSmoke;
      opacity: 0.5;
    }
  }
}
