@import "../../common";

#homeComponent {
  #hero {
    max-width: 100%;
    background: url('/images/hero.jpg') no-repeat center top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: auto 100%;
    color: $colorWhite;

    a {
      border-radius: 25px;
      border: 2px solid $colorNavy;
      color: $colorNavy;
      font-family: "Gotham A", "Gotham B", sans-serif;
      font-style: normal;
      background-color: $colorWhite;
      font-weight: 800;
      text-transform: uppercase;
      transition: background-color 200ms, color 200ms;

      &:hover {
        background-color: $colorNavy;
        color: $colorWhite;
      }

      &.active {
        border-color: $colorLime;
        background-color: $colorLime;
        color: $colorWhite;

        &:hover {
          background-color: $colorWhite;
          color: $colorLime;
        }
      }

    }

    #heroCaption {
      h1 {
        font-size: 2.81rem;
        font-family: "Gotham A", "Gotham B", sans-serif;
        font-weight: 900;
        text-transform: uppercase;
      }
    }


    p {
      font-size: 1rem;
    }

    #heroCaption {
      width: 450px;
    }
  }

}
