@import '../../common';

#topBarComponent {

  ul {

    li {
      display: inline-block;

      a {
        font-size: 0.8rem;
        color: #ffffff;
        font-weight: bolder;

        svg {
          height: 16px;
          color: #ffffff;
        }
      }

    }
  }
}
